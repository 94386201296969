import React from 'react'
import Slider from 'react-slick'

import { ITrustElement, ITrustWrapper } from '../../models/landingpage'
import { supportFormatWebp, UrlBuilder } from '../helper/picture-tag'

interface TrustWrapperProps {
  data: ITrustWrapper
}

interface TrustElementProps {
  data: ITrustElement
}

const TrustElementBox = ({ data }: TrustElementProps) => {
  console.log(data)
  return (
    <div
      className={`h-full flex flex-col flex-grow p-2 content-end justify-end items-end align-bottom`}
    >
      <img
        loading="lazy"
        src={UrlBuilder(
          data?.Icon?.fullpath ?? '/sonstiges/failover.png',
          1000,
          'png',
          false,
          undefined,
          supportFormatWebp()
        )}
        className={`w-full sm:w-6/12 mx-auto p-4 m-auto`}
      />

      <div
        className={`h-16 sm:h-auto flex flex-col justify-end text-center w-full`}
      >
        <p
          className={`relative sm:mx-auto text-base sm:text-xl content-end italic font-bold w-full font-swDisp dark:text-white text-center`}
        >
          {data.Headline}
        </p>
      </div>
      <div
        className={`h-20 sm:h-auto flex flex-col justify-end
      `}
      >
        <p
          className={`relative sm:mx-auto text-center pt-4 text-sm sm:text-lg max-w-full  font-swDisp dark:text-white `}
        >
          {data.Description}
        </p>
      </div>
    </div>
  )
}

export const TrustWrapper = ({ data }: TrustWrapperProps) => {
  const itemcount = 4

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow:
      data.Trustelemente?.length < itemcount
        ? data.Trustelemente?.length
        : itemcount,
    slidesToScroll:
      data?.Trustelemente?.length >= 4
        ? itemcount < 4
          ? itemcount
          : 4
        : data?.Trustelemente?.length < itemcount
        ? data?.Trustelemente?.length
        : itemcount,
    infinite: false,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  }
  return (
    <div className={`w-full bg-${data.Farbe} max-w-full`}>
      <div className={`z-0 container mx-auto py-16`}>
        <h3
          className={`pt-4 pb-8
           text-2xl sm:text-4xl
         italic font-bold font-swDisp text-center`}
        >
          {data.Headline}
        </h3>
        <div className="z-0 w-full">
          {data && (
            <Slider {...settings}>
              {data.Trustelemente?.map((child, index) => (
                <TrustElementBox key={index} data={child} />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrustWrapper
